import React from "react"
import Layout from "../../components/Layout"
import SubHero from "../../components/SubHero"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import Img from "gatsby-image"

export const query = graphql`
  query BlogPostOne {
    file(relativeDirectory: { eq: "blog" }, name: { eq: "blog1" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const BlogPageOne = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="For all those out there, trying."
        description="Is it fear? Laziness? Insecurity? Over-thinking? Lost sense of
            direction? Pursuit of Perfection? Where do these
            things come from? Why do they exist? Are they just a figment of our
            imagination?"
      />
      <SubHero title={null} />
      <section className="blogpost__container">
        <div className="blogpost__meta">
          <h1>For all those out there, trying.</h1>
          <p>For everyone out there,</p>
        </div>
        <div className="post">
          <p className="quote">
            Trying to “make it big” <br />
            Trying to “figure it out” <br />
            Trying to start <br />
            Trying to create <br />
            Trying to be something <br />
            Trying to be someone <br />
            Trying to be less wrong <br />
            Trying to get your shit together <br />
            Trying to, well, just try. <br />
            <b>As a millennial, I understand.</b> <br /> <br />
          </p>
          <Img fluid={data.file.childImageSharp.fluid} alt="" />
          <p>
            <br />
            Take it from a person who is trying to “<b>figure it out</b>”
            himself. Take it from a person who is surrounded by a generation who
            want to “<b>make it big</b>”, and has massive potential to, but are
            not able to for “<b>some reason</b>”. <br />
            <br /> Though I know for a fact that this happens, I just don’t
            understand why!
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            What could the reason be? How big could it possibly be that it is
            stopping us from merely trying?
          </p>
          <p>
            Is it fear? Laziness? Insecurity? Over-thinking? Lost sense of
            direction? Pursuit of Perfection? <br /> <br /> Where do these
            things come from? Why do they exist? Are they just a figment of our
            imagination? <br /> <br /> As I said, I see a tremendous amount of
            potential around me. I see a glint of “doing something” in the eyes
            of millennials. In fact, the energy is so positive and so strong
            that I can feel it.
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            Then what's stopping us from chasing our dreams? <br /> Why is it so
            difficult to “start” in the first place?
          </p>
          <p>
            Let’s figure it out. Together. Because life is too short to learn
            only from your mistakes.
            <br /> <br /> Till then, keep trying. <br /> <br /> Following up in
            the next article. <br /> <br /> Love.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPageOne
